<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5>
                {{ $store.state.sysenv.codePropagated.name }}
                <small>Charts</small>
              </h5>
            </div>

            <div class="header__right">
              <v-flex>
                <button class="jcon_add" @click="setAdd()"></button>
                <div class="j_search type01">
                  <input v-model="search" type="search" name="q" placeholder="Filter and search" />
                  <button type="submit">
                    <v-icon>mdi-magnify</v-icon>
                  </button>
                </div>
              </v-flex>
            </div>
          </div>

          <j-data-grid
            v-model="selected"
            item-key="idx"
            hide-actions
            :headers="headers"
            :items="charts"
            :search="search"
            :chart="true"
            @filtered="onFiltered"
            @sorted="onSorted"
          >
            <template v-slot:items="props">
              <tr :active="props.selected" @click="propStatus(props)">
                <td style="text-align: center;">{{ props.item.no }}</td>
                <td style="text-align: center;">
                  <!-- <img :src="props.item.filePath"> -->
                  <j-chart-svg width="100" height="100" :xmlString="props.item.xmlString" />
                </td>
                <td>{{ props.item.chartName }}</td>
                <td>{{ props.item.chartType }}</td>
                <td>{{ props.item.dbTable }}</td>
                <td>{{ props.item.reqData }}</td>
                <td>{{ props.item.desc }}</td>
              </tr>
            </template>
          </j-data-grid>
        </div>
      </div>

      <j-form-modal
        title="Chart"
        ref="formHandler"
        @create="add()"
        @edit="edit()"
        @delete="del()"
        @cancel="cancel()"
        :formMode="formode"
        :resetable="resetable()"
        :opened.sync="modOpen"
        :modalType="'type01'"
      >
        <v-container>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.chartName"
                required
                label="Chart Name"
                :rules="defaultRules"
              ></v-text-field>
            </v-flex>
            <v-flex sm5 xs12 class="wrap__select">
              <v-select
                v-model="selectedItem.dbTable"
                class="wrap__select"
                item-text="name"
                item-value="name"
                label="DB Table"
                placeholder="Select a Table"
                dense
                @input="onTableChanged"
                :menu-props="{contentClass:'single_select'}"
                :items="chartTableOption"
                :rules="defaultRules"
              ></v-select>
            </v-flex>
            <v-flex sm4 xs12>
              <v-text-field
                v-model="selectedItem.chartType"
                required
                label="Chart Type"
                :rules="defaultRules"
              ></v-text-field>
            </v-flex>
            <v-flex sm3 xs12>
              <v-text-field
                v-model="selectedItem.reqData"
                label="Requested Data"
                :rules="defaultRules"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field v-model="selectedItem.desc" label="Description" :rules="defaultRules"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <div class="file-object-wrapper">
                <v-text-field
                  v-model="files"
                  hidden
                  required
                  style="display:none;"
                  class="file-object-input"
                  :rules="fileRules"
                ></v-text-field>
                <span class="label">File Upload</span>
                <vue-dropzone
                  class="j_dropzone"
                  ref="myVueDropzone"
                  @vdropzone-error="dropError"
                  @vdropzone-complete="afterComplete"
                  :options="dropzoneOptions"
                  :include-styling="false"
                  id="customdropzone"
                  :useCustomSlot="true"
                  v-on:vdropzone-thumbnail="thumbnail"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">Choose a SVG file or drag it here</h3>
                  </div>
                </vue-dropzone>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </j-form-modal>
    </div>
    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { SystemCodeService, SystemService } from "@/services"
import JFormModal from "@/components/floating/JFormModal"
import vue2Dropzone from "vue2-dropzone";

// CSS
import "vue2-dropzone/dist/vue2Dropzone.min.css"

export default {
  name: "sysenv-icons",
  components: {
    JFormModal,
    vueDropzone: vue2Dropzone
  },
  data: () => ({
    systemCodeService: null,
    systemService: null,
    chartTableOption: [],
    image: "",
    modOpen: false,
    search: "",
    selected: [],
    selectedIndex: -3,
    selectedItem: {},
    selectedPrevItem: [],
    headers: [
      { type: "conf", text: "No",          align: "center", value: "",          width: 50,  sortable: false },
      { type: "text", text: "Chart",       align: "center", value: "",          width: 100, sortable: false },
      { type: "text", text: "Chart Name",  align: "left",   value: "chartName", width: 170, sortable: true  },
      { type: "text", text: "Chart Type",  align: "left",   value: "chartType", width: 100, sortable: true  },
      { type: "text", text: "DB Table",    align: "left",   value: "dbTable",   width: 100, sortable: true  },
      { type: "text", text: "Req. Data",   align: "center", value: "reqData",   width: 80,  sortable: true  },
      { type: "text", text: "Description", align: "left",   value: "desc",                  sortable: true  }
    ],
    charts: [],
    loading: false,
    select: null,
    formode: __C.FORM.EDIT_MODE_NEW,
    valid: false,
    files: [],
    width: 0,
    height: 0,
    defaultRules: [v => !!v || "Required"],
    nameRules: [
      v => !!v || "Name Required",
      // v => !!v && /^[A-Za-z0-9_]*$/.test(v) || 'Enter only English and numbers',
      v => (!!v && v.length >= 1) || "At least 1 characters",
      v => (!!v && v.length < 20) || "Less then 20 characters"
    ],
    fileRules: [v => !!v[0] || "file required"],
    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    dropzoneOptions: {
      url: "https://httpbin.org/post",
      maxFiles: 1,
      init: function () {
        this.hiddenFileInput.removeAttribute("multiple");
        this.on("addedfile", function (file) {
          if (!!this.files && this.files.length > 1)
            this.removeFile(this.files[0]);
        });
        this.on("maxfilesexceeded", function (file) {
          this.removeAllFiles();
          this.addFile(file);
        });
      },
      thumbnailWidth: 130,
      thumbnailHeight: 130,
      addRemoveLinks: false,
      uploadMultiple: false,
      acceptedFiles: "image/svg+xml",
      newFileChanged: false
      // previewTemplate: this.chartTemplate(),
    },
    yes: () => { }
  }),
  watch: {
    codePropagated: {
      handler(val) {
        this.headersChange(val);
        this.selectedIndex = -3;
        this.systemService.getCharts(val.code, res => {
          this.setCharts(res)
        })
        this.resetfiles();
      },
      deep: true
    }
  },
  created() {
    this.systemCodeService = new SystemCodeService();
    this.systemService = new SystemService();
  },
  mounted() {
    setTimeout(() => {
      this.systemCodeService.sysTables(
        __C.DATABASE_NAME.CHART_LIBRARY,
        __C.SCHEMA_NAME.DONUT,
        res => {
          this.chartTableOption = res;
        }
      );
      this.headersChange(this.codePropagated);
    }, 200);
  },
  computed: {
    codePropagated: {
      get() {
        return this.$store.state.sysenv.codePropagated;
      },
      set(value) {
        this.$store.commit("sysenv/codePropagate", value);
      }
    }
  },
  methods: {
    setCharts(res) {
      this.modOpen = false;
      this.charts = []

      if (!res) res = []
      // xmlStirng 값으로 이미지 랜더링하기 위함
      let _self = this
      res.map(function (data) {
        var xhr = new XMLHttpRequest()
        xhr.open("GET", `${__C.HOST_NAME}${data.filePath}`, true)
        xhr.onload = function () {
          if (this.status == 200) data.xmlString = xhr.responseText
          else console.warn('Cannot recieve the result of the svg request.')
          _self.charts.push(data)
        }
        xhr.send()
      })

      if (this.selectedIndex < -1) {
        //
      } else if (this.selectedIndex == -1) {
        this.setEdit(
          this.charts.findIndex(x => x.catCode === this.selectedItem.catCode)
        );
      } else {
        if (this.charts.findIndex(x => x.idx === this.selectedItem.idx) < 0) {
          if (this.charts.length > 0) {
            if (this.charts.length >= this.selectedIndex + 1) {
              // --
            } else {
              this.selectedIndex = this.charts.length - 1;
            }
            this.setEdit(this.selectedIndex);
          } else {
            // this.setAdd()
          }
        } else {
          this.setEdit(this.selectedIndex);
        }
      }
    },
    onFiltered(items) {
      // Renumbering for the action of Drag & Drop and search filtering.
      let reNumbering = 0;
      items.forEach(_item => {
        this.charts.find(_item_ => _item_.idx == _item.idx).no = ++reNumbering;
      });
    },
    onSorted(items) {
      items.forEach((item, index) => {
        item.no = index + 1;
      });
      // this.charts = items.slice()
    },
    add() {
      this.yes = () => {
        this.msgOpen = false;

        let formData = new FormData();
        this.files.forEach(f => {
          formData.append("charts", f);
        });

        // TODO: On multi file upload...

        formData.set("catCode", this.selectedItem.catCode);
        formData.set("chartName", this.selectedItem.chartName);
        formData.set("chartType", this.selectedItem.chartType);
        formData.set("dbTable", this.selectedItem.dbTable);
        formData.set("reqData", this.selectedItem.reqData);
        formData.set("fileExt", "svg");
        formData.set("desc", this.selectedItem.desc);

        this.systemService.putChart(formData, res => {
          this.systemService.getCharts(
            this.codePropagated.code,
            this.setCharts
          );
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to save new Chart?";
      this.msgInfo.button = [true, false, true];
      this.msgInfo.buttonText[0] = "Save";
      this.msgOpen = true;
    },
    edit() {
      this.yes = () => {
        this.msgOpen = false;

        let formData = new FormData();
        this.files.forEach(f => {
          formData.append("charts", f);
        });

        // TODO: On multi file upload... & process by the condition changed file exist

        formData.set("idx", this.selectedItem.idx);
        formData.set("catCode", this.selectedItem.catCode);
        formData.set("chartName", this.selectedItem.chartName);
        formData.set("chartType", this.selectedItem.chartType);
        formData.set("dbTable", this.selectedItem.dbTable);
        formData.set("reqData", this.selectedItem.reqData);
        formData.set("fileName", this.selectedItem.fileName);
        formData.set("fileSysName", this.selectedItem.fileSysName);
        formData.set("fileExt", this.selectedItem.fileExt);
        formData.set("filePath", this.selectedItem.filePath);
        formData.set("desc", this.selectedItem.desc);
        formData.set("fileChanged", this.newFileChanged);

        this.systemService.updChart(formData, res => {
          this.systemService.getCharts(
            this.codePropagated.code,
            this.setCharts
          );
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to save this changes?";
      this.msgInfo.button = [true, false, true];
      this.msgInfo.buttonText[0] = "Save";
      this.msgOpen = true;
    },
    del() {
      this.yes = () => {
        this.msgOpen = false;
        this.systemService.delChart(this.selectedItem.idx, res => {
          this.systemService.getCharts(
            this.codePropagated.code,
            this.setCharts
          );
        });
        this.yes = () => { };
      };
      // this.systemService.getChartRelated(this.selectedItem.catCode, this.selectedItem.idx, res=>{
      //   if(res == 0) {
      //     this.msgInfo.type = 'WARN'
      //     this.msgInfo.title = 'Action Approval'
      //     this.msgInfo.titleDescription = 'Important Notification'
      //     this.msgInfo.message = 'Do you want to delete selected chart?'
      //     this.msgInfo.button = [true, false, true]
      //     this.msgInfo.buttonText = ['Yes', 'No', 'Cancel']
      //     this.msgInfo.buttonText[0] = 'Delete'
      //     this.msgOpen = true
      //   } else {
      //     this.msgInfo.type = 'WARN'
      //     this.msgInfo.title = 'Can Not Delete'
      //     this.msgInfo.titleDescription = 'Important Notification'
      //     this.msgInfo.message = 'Related Menu Item have ' + res + ' of data. ' + 'Please delete relevant data first.'
      //     this.msgInfo.button = [false, false, true ]
      //     this.msgOpen = true
      //   }
      // })
      this.msgInfo.type = "WARN";
      this.msgInfo.title = "Action Approval";
      this.msgInfo.titleDescription = "Important Notification";
      this.msgInfo.message = "Do you want to delete selected chart?";
      this.msgInfo.button = [true, false, true];
      this.msgInfo.buttonText = ["Yes", "No", "Cancel"];
      this.msgInfo.buttonText[0] = "Delete";
      this.msgOpen = true;
    },
    propStatus(props) {
      this.selectedIndex = this.charts.indexOf(props.item);
      this.selectedItem = Object.assign({}, props.item);

      var thumbnailFile = {};
      thumbnailFile.name = props.item.fileName;
      this.thumbnail(thumbnailFile, props.item.filePath);

      if (this.selectedPrevItem.length > 0) {
        if (this.selectedPrevItem[0].idx === props.item.idx) {
          this.modOpen = true;
          return;
        } else {
          let _selected = props.item;
          this.selected = [_selected];
        }
      } else {
        props.selected = !props.selected;
      }
      this.selectedPrevItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
      this.files = [{ dummy: "dummy" }];
      this.modOpen = true;
    },
    setAdd() {
      this.selectedIndex = this.selectedIndex < -1 ? this.selectedIndex : -1;
      this.selectedItem = {};
      this.selected = [];
      this.selectedPrevItem = [];

      document.querySelector("#customdropzone").innerHTML = `
      <div class="dz-message">
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">Choose a SVG file or drag it here</h3>
        </div>
      </div>
      `;
      this.formode = __C.FORM.EDIT_MODE_NEW
      this.resetfiles();
      this.$refs.formHandler.formReset();
      this.modOpen = true;

      this.selectedItem.catCode = this.codePropagated.code;
    },
    setEdit(selectedIndex) {
      this.selectedIndex = selectedIndex;
      this.selectedItem = Object.assign({}, this.charts[this.selectedIndex]);
      this.selected = [Object.assign({}, this.charts[this.selectedIndex])];
      this.selectedPrevItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
    },
    resetable() {
      if (this.selectedIndex < 0) {
        // TODO: add the condition new file exist
        if (
          !!this.selectedItem.chartName ||
          !!this.selectedItem.chartType ||
          !!this.selectedItem.dbTable ||
          !!this.selectedItem.reqData ||
          !!this.selectedItem.desc
        )
          return true;
        return false;
      } else {
        if (
          this.selectedPrevItem[0].chartName != this.selectedItem.chartName ||
          this.selectedPrevItem[0].chartType != this.selectedItem.chartType ||
          this.selectedPrevItem[0].dbTable != this.selectedItem.dbTable ||
          this.selectedPrevItem[0].reqData != this.selectedItem.reqData ||
          this.selectedPrevItem[0].desc != this.selectedItem.desc ||
          !this.files[0].dummy
        )
          return true;
        return false;
      }
    },
    reset() {
      if (this.selectedIndex < 0) {
        this.selectedItem = {};
        this.resetfiles();
        this.$refs.formHandler.formReset();
      } else {
        this.selectedItem = Object.assign({}, this.selectedPrevItem[0]);
      }
    },
    cancel() {
      this.modOpen = false;
    },
    resetfiles() {
      if (!!this.$refs.myVueDropzone) {
        this.$refs.myVueDropzone.removeAllFiles(true);
      }
      var editThumbnail = document.querySelector("#editThumbnail");
      if (!!editThumbnail) editThumbnail.remove();
      this.files = [];
    },
    formatBytes(num) {
      if (typeof num !== "number" || isNaN(num)) {
        throw new TypeError("Expected a number");
      }

      var exponent;
      var unit;
      var neg = num < 0;
      var units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      if (neg) {
        num = -num;
      }

      if (num < 1) {
        return (neg ? "-" : "") + num + " B";
      }

      exponent = Math.min(
        Math.floor(Math.log(num) / Math.log(1000)),
        units.length - 1
      );
      num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
      unit = units[exponent];

      return (neg ? "-" : "") + num + " " + unit;
    },
    formatSize(width, height) {
      width = width.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      height = height.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return width + " X " + height;
    },
    selectedFile() {
      document.getElementById("addFile").click();
    },
    optionCheck() {
      if (this.formode == __C.FORM.EDIT_MODE_NEW) return true;
      else false;
    },
    afterComplete(file) {
      this.files = [];
      this.selectedItem.fileName = "";
      if (file.type == "image/svg+xml") {
        this.files.push(file);
      }
      this.selectedItem.fileName = file.name;
    },
    dropError(file, message, xhr) {
      if (file.type != "image/svg+xml")
        file.previewElement.children[3].innerText = "Only upload SVG file";
    },
    headersChange(val) {
      let catIndex = this.headers.findIndex(h => h.value === "catName");
      if (val.code === "ALL") {
        if (catIndex < 0)
          this.headers.unshift({
            type: "text",
            text: "Group Name",
            align: "left",
            value: "catName",
            width: 170,
            sortable: false
          });
      } else {
        if (catIndex >= 0) this.headers.shift();
      }
    },
    onTableChanged(val) {
      if (!val) return;
      this.selectedItem.chartType = val.replace(/_/g, "-");
    },
    thumbnail: function (file, dataUrl) {
      var editThumbnail = document.querySelector("#editThumbnail");
      if (!!editThumbnail) editThumbnail.remove();

      var j, len, ref, thumbnailElement;
      if (file.previewElement) {
        file.previewElement.classList.remove("dz-file-preview");
        ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
        for (j = 0, len = ref.length;j < len;j++) {
          thumbnailElement = ref[j];
          thumbnailElement.alt = file.name;
          thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
        }
        this.newFileChanged = true;
        return setTimeout(
          (function (_this) {
            return function () {
              return file.previewElement.classList.add("dz-image-preview");
            };
          })(this),
          100
        );
      } else {
        document.querySelector("#customdropzone").innerHTML = `
          <div id="editThumbnail" class="dz-preview dz-file-preview">
            <div class="dz-image">
              <div data-dz-thumbnail-bg>
                <img id='previewImg' />
              </div>
            </div>
            <div class="dz-details">
              <div class="dz-size"><span data-dz-size></span></div>
              <div class="dz-filename"><span data-dz-name></span></div>
            </div>
            <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
            <div class="dz-error-message" style="color: red"><span data-dz-errormessage style="color: red"></span></div>
            <div class="dz-success-mark"><i class="fa fa-check"></i></div>
            <div class="dz-error-mark"><i class="fa fa-close"></i></div>
          </div>
        `;
        this.newFileChanged = false;

        var previewImg = document.querySelector("#previewImg");
        document.querySelector(".dz-filename span").innerText = file.name;
        previewImg.alt = file.name;
        previewImg.src = dataUrl;
      }
    },
  }
};
</script>
